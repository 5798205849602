.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Basics-list {
display:flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
}

.Why-section{
  margin: 2rem 20rem
}

.Boxed-text{
  background-color: #282c34;
  border-radius: 8px;
  padding:10px;
  margin-left:10rem;
  margin-right:10rem;
  color:#FFFFFF;
}

.Navigation-bar-container{
  display:flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
}

.Nav-link-button{
  background-color: #282c34;
  border: 1px solid #000000;
  border-radius: 3px;
  padding:1rem;
  color: #FFFFFF;
  margin:1px
}
.Nav-link{
text-decoration: none;
  color: #FFFFFF;
}

.Active{
  background-color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
